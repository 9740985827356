









import { Component, Prop, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import { getNodeApiUrlByVersion } from "@/config";
import baseHttp from "@/shared/http";
import CoursesMaterial from "@/shared/modules/courses-materials/courses-material.model";
import cafePdf from "custom-cafe-pdf";

@Component({
  methods: { __ },
  components: { cafePdf }
})
export default class CourseMaterialAttachment extends Vue {
  @Prop() courseMaterial!: CoursesMaterial
  @Prop() lang!: string
  http = baseHttp

  isPdf = false
  imgSrc = ''

  created() {
    if ((this.courseMaterial?.picture as any)?.[this.lang]?.url) {
      if ((this.courseMaterial?.picture as any)?.[this.lang]?.name?.endsWith(".pdf")) this.isPdf = true;
      this.http.get(`${getNodeApiUrlByVersion()}/s3-signed-url?key=${(this.courseMaterial.picture as any)?.[this.lang]?.url}`)
        .then(resp => this.imgSrc = resp.data);
    }
  }
}
