



























































































































import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/admin/components/PageHeader.vue";
import __ from "@/shared/helpers/__";
import { CompanyRoutes } from "@/shared/router/company";
import { getNodeApiUrlByVersion } from "@/config";
import baseHttp from "@/shared/http";
import FormField from "@/shared/components/form/FormField.vue";
import FormBase from "@/shared/classes/form/form-base";
import Form from "@/shared/components/form/Form.vue";
import CourseTestFinished from "@/company/components/courses/CourseTestFinished.vue";
import { getVersion } from "@/shared/helpers/version.helper";
import CourseMaterialAttachment from "@/company/components/courses/CourseMaterialAttachment.vue";
import { GlobalActions } from "@/shared/store/global/global.actions";
import CourseMaterialVideoDialog from "@/company/components/courses/CourseMaterialVideoDialog.vue";
import store from "@/shared/store";
import localeHelper from "@/shared/helpers/locale.helper";
import CourseAssignment from "@/shared/modules/course-assignments/course-assignment.model";

const LANG_MAP: Record<string, any> = {
  'en': {
    'en': 'English',
    'lt': 'Lithuanian',
    'no': 'Norwegian',
    'pl': 'Polish',
    'ru': 'Ukrainian',
  },
  'lt': {
    'en': 'Anglų',
    'lt': 'Lietuvių',
    'no': 'Norvegų',
    'pl': 'Lenkų',
    'ru': 'Ukrainiečių',
  },
  'no': {
    'en': 'Engelsk',
    'lt': 'Litauisk',
    'no': 'Norsk',
    'pl': 'Polsk',
    'ru': 'Ukrainsk',
  },
  'pl': {
    'en': 'Angielskim',
    'lt': 'Litewskim',
    'no': 'Norweskim',
    'pl': 'Polskim',
    'ru': 'Ukraińskim',
  },
  'ru': {
    'en': 'Английском',
    'lt': 'Литовском',
    'no': 'Норвежском',
    'pl': 'Польском',
    'ru': 'Украинском',
  }
}

@Component({
  components: { CourseTestFinished, PageHeader, FormField, Form, CourseMaterialAttachment },
  methods: { __ }
})
export default class CourseMaterials extends Vue {
  http = baseHttp

  courseAssignment: CourseAssignment | null = null
  course: any = null
  materialsAttemptUuid = '';
  form = new FormBase()
    .setTranslatable(true)
    .setSubmit(false);
  activeMaterialIndex = -1
  lastMaterialViewedIndex = -1
  loading = true
  nextDisabled = false

  created() {
    this.http.get(`${getNodeApiUrlByVersion()}/course-assignments/${this.$route.params.uuid}`)
      .then(resp => {
        this.courseAssignment = resp.data;
        this.course = resp.data?.course;
        if (typeof resp.data?.lastMaterialViewedIndex === 'number') {
          this.lastMaterialViewedIndex = resp.data?.lastMaterialViewedIndex
        }
        this.http.post(`${getNodeApiUrlByVersion()}/course-assignments/materials-attempt-started/${this.$route.params.uuid}`, {
          materialsStartedAt: new Date()
        })
          .then(response => this.materialsAttemptUuid = response.data?.uuid)
          .catch(console.warn)
      })
      .catch(console.warn)
      .finally(() => this.loading = false)

    this.$watch('activeMaterialIndex', () => {
      if (this.activeMaterialIndex === -1) return;

      // Has video material
      if (
        Object.values(this.activeMaterial.linkToVideo || {}).filter(Boolean).length
        && this.lastMaterialViewedIndex < this.activeMaterialIndex
      ) {
        const languages: string[] = [];
        Object.entries(this.activeMaterial.linkToVideo || {})
          .forEach(([key, value]) => {
            if (value) languages.push(LANG_MAP[localeHelper.getLocale()][key])
          });
        const [last, ...all] = languages.reverse();
        this.nextDisabled = true;
        store.dispatch(GlobalActions.showSnackBar, {
          type: 'orange',
          message: `${__('company.views.courses.materials.watch-notice-1')} ${all.join(', ')}${languages.length > 1 ? ` ${__('company.views.courses.materials.or')} ` : ''}${last} ${__('company.views.courses.materials.watch-notice-2')}`
        })
      }
      else {
        this.nextDisabled = false;
        this.markMaterialAsViewed();
      }
    })
  }

  goBack() {
    this.$router.push({ name: CompanyRoutes.coursesIndex })
  }

  goToTest() {
    this.$router.push({ name: CompanyRoutes.coursesTestIndex, params: { uuid: this.$route.params.uuid } })
  }

  onPrev() {
    this.activeMaterialIndex--;
  }

  onNext() {
    this.activeMaterialIndex++;
  }

  markMaterialAsViewed() {
    this.http.post(`${getNodeApiUrlByVersion()}/course-assignments/materials-attempt-finished/${this.$route.params.uuid}`, {
      materialsFinishedAt: new Date(),
      attemptUuid: this.materialsAttemptUuid,
      lastMaterialViewedIndex: this.activeMaterialIndex
    })
      .catch(console.warn)

    if (this.activeMaterialIndex > this.lastMaterialViewedIndex) {
      this.lastMaterialViewedIndex = this.activeMaterialIndex
    }
  }

  get courseTitle() {
    return this.course?.name?.[this.form.currentLanguage];
  }

  get courseMaterialDescription() {
    return this.course?.materialsDescription?.[this.form.currentLanguage] || '';
  }

  get materialsAmount() {
    return this.course?.materials?.length || 0;
  }

  get currentRegionLanguage() {
    return getVersion()
  }

  get regionPaneIsVisible() {
    return window.innerWidth >= 960
  }

  get activeMaterial() {
    return this.course?.materials?.[this.activeMaterialIndex];
  }

  get testPassed() {
    return this.courseAssignment?.status === 'completed';
  }

  courseMaterialContent(lang: string) {
    return this.activeMaterial?.text?.[lang] || '';
  }

  courseMaterialTitle(lang: string) {
    return this.activeMaterial?.name?.[lang] || '';
  }

  courseMaterialVideo(lang: string) {
    return this.activeMaterial?.linkToVideo?.[lang] || '';
  }

  openMaterialsVideoDialog(link: string): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CourseMaterialVideoDialog,
      maxWidth: 1000,
      persistent: true,
      noClose: true,
      meta: {
        link: link,
        onFinish: () => {
          this.nextDisabled = false;
          this.markMaterialAsViewed();
        }
      },
    })
  }
}
