








import { Component, Prop, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import PageHeader from "@/admin/components/PageHeader.vue";
import Form from "@/shared/components/form/Form.vue";
import { GlobalActions } from "@/shared/store/global/global.actions";
import Player from '@vimeo/player';

@Component({
  components: { Form, PageHeader },
  methods: { __ },
})
export default class CourseMaterialVideoDialog extends Vue {
  @Prop() meta!: any

  mounted() {
    const videoPlayer = new Player('player', {
      url: this.meta.link,
      autoplay: true,
      controls: false,
      keyboard: false,
    });
    videoPlayer.on('ended', () => {
      this.closeDialog();
    });
  }

  async closeDialog() {
    await this.$store.dispatch(GlobalActions.closeDialog)
    if (typeof this.meta.onFinish === 'function') this.meta.onFinish();
  }
}
